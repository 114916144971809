import * as React from 'react';
import { Box, Grid, Divider, Paper } from '@mui/material';

import Header from './Header/Header';
import ActionPanel from './ActionPanel/ActionPanel';
import Monitor, { GraphsBlock } from './Monitor/Monitor';
import TerminalInformation from './TerminalInformation/TerminalInformation';
import TerminalScreenshot from './TerminalScreenshot/TerminalScreenshot';
import BackdropSpinner from 'components/Common/BackdropSpinner';
import ConfirmModal from 'components/Modal/ConfirmModal/ConfirmModal';
import MaintenanceModal from 'pages/Epos/components/MaintenanceModal/MaintenanceModal';
import UpdateShellModal from 'pages/Epos/components/UpdateShellModal/UpdateShellModal';

import {
    GRID_COLUMNS as columns,
    ADDITIONAL_DATA_REQUEST_INTERVAL,
    METRIC_TYPES,
    COMMAND_TYPES,
    IMAGE_TYPES,
    EPOS_STATUS_NAMES,
    EPOS_STATUS
} from 'const';

import { transformDate } from 'utils/formatDate';
import { cancelRequests, isCanceled } from 'utils/requestCancelation';

import { GlobalContext } from 'context/globalContext';
import { EposContext, getEposById } from 'context/epos';
import { withRouter } from 'react-router-dom';

import { IEposListItemModel, IBulkActions } from 'api/models/epos';
import { MetricModel } from 'api/models/metric';
import { GlobalContextModel } from 'api/models/general';

import {
    bulkActions,
    MaintenanceModeEnable,
    MaintenanceModeDisable,
    UpdateShell,
    SwitchToProductionBulkAction,
    SwitchToCanaryBulkAction
} from 'data';

import Api from 'api/Api';
import useStyles from './styles';

import { useWidth } from 'utils/customHooks';

const onOpenLinkClick = (url) => {
    window.open(url, '_blank');
};

const onOpenExternalLinkWithLogging = (eposItem, url, actionTypeId) => {
    Api.AuditLog.SaveLog({
        actionTypeId,
        description: `${eposItem.name} (${eposItem.macAddress})`
    });
    onOpenLinkClick(url);
};

const reducer = (state, action) => {
    switch (action.type) {
        case 'LOAD_ADDITONAL_DATA':
            return { ...state, ...action.payload };
        case 'LOAD_SCREENSHOTS':
            return {
                ...state,
                isScreenShotLoaded: true,
                mainScreen: action.payload[0],
                upperScreen: action.payload[1]
            };
        case 'LOAD_FAILURE':
        // handle fetch errors here
        default:
            return state;
    }
};

const { useState, useContext, useReducer, useEffect } = React;

const apiRequests = {
    eposAdditionalData: null,
    terminalImages: null
};

const windowsOS = 'Windows';

export const EposDetails = (props) => {
    const { history, match } = props;
    const [additionalData, dispatch] = useReducer(reducer, {});
    const classes = useStyles({});

    const isMobileView = useWidth() === 'xs';

    const { globalSettings, permissions }: GlobalContextModel = useContext(GlobalContext);
    const { isLoaded } = useContext(EposContext);

    const eposItem: Partial<IEposListItemModel> = getEposById(Number(match.params.id)) || {};

    const metrics: MetricModel = additionalData.metrics || [];

    const getMetricByType = (metricType: METRIC_TYPES) => metrics[metricType];

    const [bulkActionItem, setBulkActionItem] = useState({});

    const [isOpenModal, setIsOpenModal] = useState(false);
    const [isOpenMaintenanceModal, setIsOpenMaintenanceModal] = useState(false);
    const [isOpenUpdateShellModal, setIsOpenUpdateShellModal] = useState(false);

    const {
        restartTerminalPermission,
        sshPermission,
        vncPermission,
        maintenancePermission,
        technicianPagePermission,
        reinitBillValPermission,
        restartShellPermission,
        restartPrinterPermission,
        updateShellPermission,
        switchEnvironment,
    } = permissions;

    const anyActionPermission = restartTerminalPermission ||
        sshPermission ||
        technicianPagePermission ||
        maintenancePermission ||
        updateShellPermission ||
        switchEnvironment;

    const onBulkActionTriggered = (commandTypeId: string | COMMAND_TYPES) => {
        setIsOpenModal(true);
        setBulkActionItem([
            ...bulkActions,
            SwitchToProductionBulkAction,
            SwitchToCanaryBulkAction
        ].find(action => action.commandTypeId === commandTypeId));
    };

    const hardwareMonitorBlocks = {
        printerBlock: {
            metric: getMetricByType(METRIC_TYPES.PRINTER),
            buttons: [
                {
                    label: 'hmd-restart',
                    permission: restartPrinterPermission,
                    onClick: () => onBulkActionTriggered(COMMAND_TYPES.RestartPrinter),
                }
            ]
        },
        billAcceptorBlock: {
            metric: getMetricByType(METRIC_TYPES.BILL_ACCEPTOR),
            buttons: [
                {
                    label: 'hmd-reinitialize',
                    permission: reinitBillValPermission,
                    onClick: () => onBulkActionTriggered(COMMAND_TYPES.ReInitBillAcceptor),
                }
            ]
        }
    };

    const activityMonitorBlocks = {
        shellBlock: {
            metric: getMetricByType(METRIC_TYPES.SHELL),
            buttons: [
                {
                    label: 'hmd-restart-shell',
                    permission: restartShellPermission,
                    onClick: () => onBulkActionTriggered(COMMAND_TYPES.RestartShell),
                }
            ]
        },
        shellUpdateBlock: {
            metric: getMetricByType(METRIC_TYPES.SHELL_UPADATE)?.properties?.UpdateStatus !== 'None' &&
                    getMetricByType(METRIC_TYPES.SHELL_UPADATE),
        },
        networkBlock: {
            metric: getMetricByType(METRIC_TYPES.NETWORK)
        }
    };

    const networkMetrics = activityMonitorBlocks.networkBlock.metric;
    const networkProperties = networkMetrics?.properties;
    const vpnIp = networkProperties?.VpnIp;
    const grafanaUrl = isLoaded && eposItem?.os?.includes(windowsOS)
        ? globalSettings.settings?.GraphanaUrlWindows
        : globalSettings.settings?.GraphanaUrlLinux;

    const isStatusOnline = getMetricByType(METRIC_TYPES.STATUS)?.status === true;
    const isUnderMaintenance = activityMonitorBlocks.shellBlock.metric?.properties?.IsInMaintenanceMode === 'true';
    const isPendingUnderMaintenance = activityMonitorBlocks.shellBlock.metric?.properties?.IsPendingMaintenanceMode === 'true';

    let eposStatus = eposItem.status;
    let eposStatusName = eposItem.statusName;

    if (isStatusOnline) {
        eposStatus = EPOS_STATUS.ONLINE;
        eposStatusName = EPOS_STATUS_NAMES.ONLINE;

        if (metrics && Object.values(metrics).find((metric) => metric.errors && !!metric.errors.length)) {
            eposStatusName = EPOS_STATUS_NAMES.WARNING;
        }

        if (isPendingUnderMaintenance) {
            eposStatus = EPOS_STATUS.PENDING_MAINTENANCE;
            eposStatusName = EPOS_STATUS_NAMES.PENDING_MAINTENANCE;
        } else {
            if (isUnderMaintenance) {
                eposStatus = EPOS_STATUS.MAINTENANCE;
                eposStatusName = EPOS_STATUS_NAMES.MAINTENANCE;
            }
        }
    } else {
        eposStatus = EPOS_STATUS.OFFLINE;
        eposStatusName = EPOS_STATUS_NAMES.OFFLINE;
    }

    const upDownTime = transformDate(eposItem.deliveryTime);

    const loadAdditionalData = () => {
        getEposAdditionalData();
        getTerminalImages();
    };

    const getEposAdditionalData = async () => {
        try {
            apiRequests.eposAdditionalData = Api.Epos.GetEposAdditionalData(match.params.id, eposItem.machineId);

            const result = await apiRequests.eposAdditionalData;

            result && dispatch({ type: 'LOAD_ADDITONAL_DATA', payload: result });
        } catch (error) {
            !isCanceled(error) && dispatch({ type: 'LOAD_FAILURE', error });
        }
    };

    const getTerminalImages = async () => {
        try {
            apiRequests.terminalImages = Api.Image.GetTerminalImages(
                [eposItem.machineId],
                [IMAGE_TYPES.Main, IMAGE_TYPES.Second]
            );

            const screenShots = await apiRequests.terminalImages;

            screenShots && dispatch({ type: 'LOAD_SCREENSHOTS', payload: screenShots[eposItem.machineId] });
        } catch (error) {
            !isCanceled(error) && dispatch({ type: 'LOAD_FAILURE', error });
        }
    };

    const handleCloseModal = () => {
        setIsOpenModal(false);
        setIsOpenMaintenanceModal(false);
        setIsOpenUpdateShellModal(false);
    };

    const handleSubmit = (id: number) => {
        setIsOpenModal(false);
        Api.Epos.BulkAction([eposItem.machineId], id);
    };

    const handleMaintenanceMode = () => {
        if (isUnderMaintenance) {
            setBulkActionItem(MaintenanceModeDisable);
            setIsOpenModal(true);
        } else {
            setBulkActionItem(MaintenanceModeEnable);
            setIsOpenMaintenanceModal(true);
        }
    };

    const openUpdateShellModal = () => {
        setIsOpenUpdateShellModal(true);
    };

    const handleSubmitMaintenance = (id: number, params: Array<string>) => {
        Api.Epos.BulkAction([eposItem.machineId], id, params);
        handleCloseModal();
    };

    const handleUpdateShell = (shellVersion: string) => {
        Api.Epos.BulkAction([eposItem.machineId], COMMAND_TYPES.ShellUpdate, [shellVersion]);
        handleCloseModal();
    };

    useEffect(() => {
        eposItem.machineId && loadAdditionalData();

        const interval = setInterval(loadAdditionalData, ADDITIONAL_DATA_REQUEST_INTERVAL);

        return () => {
            interval && clearInterval(interval);
        };
    }, [eposItem.machineId]);

    useEffect(() => {
        return () => cancelRequests(apiRequests);
    }, []);

    return (
        <div className={classes.root}>
            <div className={classes.header}>
                <Header
                    history={history}
                    epos={{
                        name: eposItem.name,
                        status: eposStatusName === EPOS_STATUS_NAMES.WARNING ? EPOS_STATUS.WARNING : eposStatus,
                        statusName: eposStatusName,
                        upDownTime,
                        isTest: eposItem.isTest
                    }}
                />
            </div>
            <Divider />
            <Paper className={classes.bodyWrap} elevation={0}>
                <BackdropSpinner open={!isLoaded} />
                <div className={classes.bodyWrapContainer}>
                    {
                        anyActionPermission &&
                            <Box className={classes.actionWrap}>
                                <ActionPanel
                                    onOpenLinkClick={onOpenExternalLinkWithLogging.bind(null, eposItem)}
                                    handleMaintenanceMode={handleMaintenanceMode}
                                    openUpdateShellModal={openUpdateShellModal}
                                    isUnderMaintenance={isUnderMaintenance}
                                    links={additionalData.remoteUrls ? additionalData.remoteUrls : []}
                                    statusName={eposStatusName}
                                    onBulkActionTriggered={onBulkActionTriggered}
                                    environmentType={eposItem.environmentType}
                                    permissions={permissions}
                                />
                            </Box>
                    }

                    <Box className={classes.content}>
                        <Grid container spacing={3} alignItems="flex-start">
                            <Grid item md={columns.col8} xs={columns.col12} className={classes.metrics}>
                                <Box paddingBottom={3}>
                                    <Monitor title="hmd-hardware-monitor" metricBlocks={hardwareMonitorBlocks} eposStatus={eposStatus} />
                                </Box>
                                <Box paddingBottom={3}>
                                    <Monitor title="hmd-activity-monitor" metricBlocks={activityMonitorBlocks} eposStatus={eposStatus} />
                                </Box>
                                {
                                    eposStatus !== EPOS_STATUS.OFFLINE && grafanaUrl && vpnIp &&
                                        <GraphsBlock
                                            src={`${grafanaUrl}${vpnIp}`}
                                        />
                                }
                            </Grid>
                            <Grid item md={columns.col4} xs={columns.col12} className={classes.terminalInfoWrap}>
                                <TerminalInformation
                                    additionalData={additionalData}
                                    eposItem={eposItem}
                                />
                                <TerminalScreenshot
                                    additionalData={additionalData}
                                    onOpenLinkClick={onOpenExternalLinkWithLogging.bind(null, eposItem)}
                                    eposItem={eposItem}
                                    vncPermission={vncPermission}
                                />
                            </Grid>
                        </Grid>
                    </Box>
                    <ConfirmModal
                        id={(bulkActionItem as IBulkActions).commandTypeId}
                        isOpen={isOpenModal}
                        title={(bulkActionItem as IBulkActions).title}
                        description={(bulkActionItem as IBulkActions).description}
                        onClose={handleCloseModal}
                        onConfirm={handleSubmit}
                    />
                    <MaintenanceModal
                        id={COMMAND_TYPES.EnableMaintenanceMode}
                        isOpen={isOpenMaintenanceModal}
                        onClose={handleCloseModal}
                        onSubmit={handleSubmitMaintenance}
                        isMobileView={isMobileView}
                    />
                    <UpdateShellModal
                        isOpen={isOpenUpdateShellModal}
                        title={UpdateShell.title}
                        onClose={handleCloseModal}
                        onConfirm={handleUpdateShell}
                    />
                </div>
            </Paper>
        </div>
    );
};

export default withRouter(EposDetails);
